import { axiosMethod } from "./helper";

export const signUp = (user) => {
  return axiosMethod
    .post("/account/register", user)
    .then((response) => response.data)
    .catch((error) => {});
};

export const getResumeAllData = (config) => {
  return axiosMethod
    .get("/resume/getallresume", config)
    .then((response) => response.data)
    .catch((error) => {});
};

export const getResumeAllDataByEmail = (email, config) => {
  return axiosMethod
    .get(`/resume/getresumebyemail/${email}`, config)
    .then((response) => response.data)
    .catch((error) => {});
};

export const getUsersAllData = (config) => {
  return axiosMethod
    .get("/account/getQueryAllUsers", config)
    .then((response) => response.data)
    .catch((error) => {});
};

export const getResumeData = (config) => {
  return axiosMethod.get(`/resume/getresume/${config.id}`, config.config);
};

export const downloadResumeById = async (resumeUUID, config) => {
  try {
    const response = await axiosMethod.get(
      `/resume/downloadresume/${resumeUUID}`,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to download resume.");
  }
};

export const deleteUserById = async (userUUID, config) => {
  return await axiosMethod.put(`/account/deletebyid/${userUUID}`, {}, config);
};

export const resumeSave = (config, data) => {
  return axiosMethod
    .post("/resume/saveresume", data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const projectDetailsSave = (data, config) => {
  return axiosMethod
    .post("/project-details", data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const projectDetailsUpdate = (id, data, config) => {
  return axiosMethod
    .put(`/project-details/${id}`, data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const projectUpdatesUpdate = (id, data, config) => {
  return axiosMethod
    .put(`project-updates/${id}`, data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const projectUpdatesSave = (config, data) => {
  return axiosMethod
    .post("/project-updates", data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getProjectUpdates = (email, headers) => {
  return axiosMethod.get(`/project-updates/user/${email}`, headers);
};

export const getProjectDetails = (email, headers) => {
  return axiosMethod.get(`/project-details/user/${email}`, headers);
};

export const getProjectDetailsForAdmin = (headers) => {
  return axiosMethod.get(`/project-details/all`, headers);
};

export const getProjectUpdatesForAdmin = (headers) => {
  return axiosMethod.get(`/project-updates/all`, headers);
};

export const deleteProjectDetails = (id, config) => {
  return axiosMethod.put(`/project-details/delete/${id}`, {}, config);
};

export const deleteProjectUpdates = (id, config) => {
  return axiosMethod.put(`/project-updates/delete/${id}`, {}, config);
};

export const getProjectDetailsById = (id, config) => {
  return axiosMethod.get(`/project-details/${id}`, config);
};

export const getProjectUpdatesById = (id, config) => {
  return axiosMethod.get(`/project-updates/${id}`, config);
};

export const checkEmail = (emailId) => {
  return axiosMethod.get(`/account/checkemail/${emailId.email}`);
};

export const editResumeById = async (resumeUUID, config, item) => {
  return await axiosMethod
    .put(`/resume/updateresume/${resumeUUID}`, item, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const deleteResumeById = async (resumeUUID, config) => {
  return await axiosMethod.put(
    `/resume/deleteresume/${resumeUUID}`,
    {},
    config
  );
};

export const getUserByUUID = (userid) => {
  return axiosMethod
    .get(`/account/user/${userid.userUUID}`, userid.headers)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getUserByID = (userid) => {
  return axiosMethod
    .get(`/account/user/${userid.id}`, userid.headers)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const resetPassword = async (data, config) => {
  return await axiosMethod
    .post(`/account/password-reset`, data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const updateUser = async (id, data, config) => {
  return await axiosMethod
    .put(`/account/user/update/${id}`, data, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getInterviewers = async (config) => {
  return await axiosMethod.get(`/account/interviewers`, config);
};

export const downloadResumeByIdWord = async (resumeUUID, config) => {
  try {
    const response = await axiosMethod.get(
      `/resume/downloadword/${resumeUUID}`,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to download resume.");
  }
};

export const findUserNameByEmail = async (email, config) => {
  return await axiosMethod
    .get(`/account/finduserbyemail/${email}`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log("error-->", error);
    });
};

export const getSummaryResume = async (config) => {
  return await axiosMethod
    .get(`resume/getsummary`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log("error-->", error);
    });
};

export const getSummaryResumeByEmail = async (email, config) => {
  return await axiosMethod
    .get(`resume/getsummary/${email}`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log("error-->", error);
    });
};

export const getTechNames = async (config) => {
  return await axiosMethod
    .get(`skill/findalltech`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log("error-->", error);
    });
};

export const getUserWithTechNames = async (config) => {
  return await axiosMethod
    .get(`/account/user/tech`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log("error-->", error);
    });
};
