 import "./Spinner.css";

import React from 'react';

function Spinner() {
  return (
    <div className="spinner">
    </div>
  );
}

export default Spinner;