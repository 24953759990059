import { columnDefsProjectUpdates } from "../../utils/AgGridTableColumns";
import {
  DeleteRounded,
  EditRounded,
  PreviewRounded,
} from "@mui/icons-material";
import { Button, IconButton, InputBase, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AgGridTable from "../AgGridTable/AgGridTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../reduxToolkit/Notification/notificationSlice";
import { useDispatch } from "react-redux";
import {
  deleteProjectUpdates,
  getProjectUpdates,
  getProjectUpdatesForAdmin,
} from "../../services/resumemaker-services";

const ProjectUpdateDahsBoard = () => {
  const [projectUpdatesData, setProjectUpdatesData] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const buttonRendererView = (props) => {
    return (
      <IconButton
        size="small"
        onClick={() => navigate(`/resumemakerui/feedback/${props.data.formId}`)}
        color="primary"
      >
        <PreviewRounded />
      </IconButton>
    );
  };

  const deleteProjectUpdatesForm = async (formId) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const res = await deleteProjectUpdates(formId, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        dispatch(setMultiNotificationVariant("success"));
        const errorArray = [
          {
            propertyValue: "Feedback Deleted Successfully.",
          },
        ];
        dispatch(setMultiNotificationData(errorArray));
        fetchdata();
      } else {
        dispatch(setMultiNotificationVariant("error"));
        const errorArray = [
          {
            propertyValue: "Something went wrong.",
          },
        ];
        dispatch(setMultiNotificationData(errorArray));
      }
    }
  };

  const buttonRendererDelete = (props) => {
    console.log(props);
    return (
      <IconButton
        size="small"
        onClick={() => deleteProjectUpdatesForm(props.data.updateId)}
        color="error"
      >
        <DeleteRounded />
      </IconButton>
    );
  };

  const handleUpdatePrject = (id) => {
    navigate(`/resumemakerui/project-updates/true/${id}`);
  };

  const buttonRendererUpdate = (props) => {
    return (
      <IconButton
        size="small"
        onClick={() => handleUpdatePrject(props.data.updateId)}
        color="success"
      >
        <EditRounded />
      </IconButton>
    );
  };

  const gridOptionsfeedback = {
    headerHeight: 36,
    columnDefs: columnDefsProjectUpdates,
    frameworkComponents: {
      buttonRendererViewResume: buttonRendererView,
      buttonRendererDeleteResume: buttonRendererDelete,
      buttonRendererUpdateResume: buttonRendererUpdate,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
  };
  function CustomNoRowsOverlay() {
    return (
      <div
        className="ag-overlay-no-rows-wrapper"
        style={{
          backgroundColor: "white",
          padding: "20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        <div style={{ textAlign: "center" }}>
          NO DATA FOUND FOR LOGGED IN USER
        </div>
      </div>
    );
  }
  async function fetchdata() {
    if (
      localStorage.getItem("role") === "ADMIN" ||
      localStorage.getItem("role") === "INTERNAL"
    ) {
      console.log("admin");
      const res = await getProjectUpdatesForAdmin({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setProjectUpdatesData(res.data || []);
    } else {
      const res = await getProjectUpdates(localStorage.getItem("email"), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setProjectUpdatesData(res.data || []);
    }
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const handleAddUpdate = () => {
    navigate("/resumemakerui/project-updates/false/0");
  };

  return (
    <>
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          height: "635px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <div>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center", width: 300 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Project"
                inputProps={{ "aria-label": "search name" }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e?.target?.value || "");
                }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              sx={{ backgroundColor: "rgb(33, 80, 162)" }}
              onClick={handleAddUpdate}
            >
              Add Updates
            </Button>
          </Grid>
        </div>
        <AgGridTable
          searchData={searchValue}
          gridOptions={gridOptionsfeedback}
          data={projectUpdatesData}
          type="projectUpdates"
        />
      </Paper>
    </>
  );
};

export default ProjectUpdateDahsBoard;
