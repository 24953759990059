import React, { useState, useEffect } from 'react';
import {findSkills, getFilteredData,} from "../../services/feedback-service";
import {
  Autocomplete ,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  InputBase,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import '../../styles/Filter.css';
import InitialsAvatar from 'react-initials-avatar';
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const Filter = () => {
  const [data, setData] = useState([]);
  const [filterdata, setFilterdata] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologyList, setTechnologyList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState('');
  const [selectedResult, setSelectedResult] = useState('');
  const [selectedScore, setSelectedScore] = useState('');
  const navigate =useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Number of items to show per page

  async function fetchData() {
    const res = await getFilteredData({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    setData(res);

    const technologies = await findSkills({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    setTechnologyList(technologies);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleTechnologyChange = (event, values) => {
    setSelectedTechnologies(values);
    setSelectedSkills([]);
  };

  const handleSkillsChange = (event, values) => {
    setSelectedSkills(values);
  };

  const handleExperienceChange = (event) => {
    setSelectedExperience(event.target.value);
  };

  const handleResultChange = (event) => {
    setSelectedResult(event.target.value);
  };

  const handleScoreChange = (event) => {
    setSelectedScore(event.target.value);
  };

  const filterBySearchText = (item) => {
    return item.candidateName.toLowerCase().includes(searchText.toLowerCase());
  };

  const filterByTechnologies = (item) => {
    if (selectedTechnologies.length === 0) {
      return true;
    }
    return  selectedTechnologies.some((selectedTech) =>
        item.techName === selectedTech
    );
  };

  // const filterBySkills = (item) => {
  //   if (selectedSkills.length === 0) {
  //     return true;
  //   }
  //   return  selectedTechnologies.some((techRating) =>
  //       selectedSkills.some((selectedSkill) =>
  //           item.skillNames === selectedSkill
  //       )
  //   );
  // };
  const filterBySkills = (item) => {
    if (selectedSkills.length === 0) {
      return true;
    }
    const itemSkills = item.skillNames.split(", ");

    return selectedTechnologies.some((selectedTech) =>
        selectedSkills.some((techRating) =>
            item.techName === selectedTech &&
            itemSkills.includes(techRating)
        )
    );
  };

  const filterByExperience = (item) => {
    if (selectedExperience === '') {
      return true;
    }
    return item.experience === selectedExperience;
  };

  const filterByResult = (item) => {
    if (selectedResult === '') {
      return true;
    }
    return item.result === selectedResult;
  };

  const filterByScore = (dataList) => {
    if (selectedScore === '') {
      return dataList;
    }
    let tempSort=[]
    if (selectedScore === 'high-to-low') {
      tempSort= dataList.sort((a, b) => b.totalScore - a.totalScore);
    } else if (selectedScore === 'low-to-high') {
      tempSort= dataList.sort((a, b) => a.totalScore - b.totalScore);
    }

    return tempSort;
  };

  const applyFilters = () => {
    let filteredData = data.filter(
        (item) =>
            filterBySearchText(item) &&
            filterByTechnologies(item) &&
            filterBySkills(item) &&
            filterByExperience(item) &&
            filterByResult(item)
    );

    filteredData = filterByScore(filteredData);

    return filteredData;
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (data && data.length) {
      const filteredData = applyFilters();
      setFilterdata(filteredData);
      setCurrentPage(1); // Reset current page to the first page when filters are applied
    }
  }, [
    data,
    selectedScore,
    selectedResult,
    selectedExperience,
    selectedSkills,
    technologyList,
    searchText,
  ]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filterdata.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filterdata.length / itemsPerPage);

  const handleReset = () => {
    setSearchText('');
    setSelectedTechnologies([]);
    setSelectedSkills([]);
    setSelectedExperience('');
    setSelectedResult('');
    setSelectedScore('');
    //setCurrentPage(1); // Reset the current page to the first page
  };

  const paginationStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  };

  return (
      <Paper className='filtermain'
             style={{backgroundColor: "#F0F0F0"}}>

        <Paper className='filterMenu'>
          <Grid container spacing={2} alignItems="center">

            <Grid  item xs={6} sm={6} md={6} lg={4}>

              <Autocomplete
                  multiple
                  value={selectedTechnologies}
                  onChange={handleTechnologyChange}
                  options={technologyList.map((techRating) => techRating.techName)}
                  renderInput={(params) => (
                      <TextField {...params} label="Technologies" placeholder="Select Technologies" />
                  )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>

              <Autocomplete
                  multiple
                  value={selectedSkills}
                  onChange={handleSkillsChange}
                  options={technologyList
                      .filter((techRating) => selectedTechnologies.includes(techRating.techName))
                      .flatMap((techRating) => techRating.techSkills.map((skill) => skill.skillName))
                  }
                  renderInput={(params) => (
                      <TextField {...params} label="Skills" placeholder="Select Skills" />
                  )}
              />
            </Grid>

            <Grid  item xs={6} sm={6} md={6} lg={4}>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Result</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedResult}
                    label="Result"
                    onChange={handleResultChange}
                >
                  <MenuItem value="SELECTED">SELECTED</MenuItem>
                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                  <MenuItem value="HOLD">HOLD</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid  item xs={6} sm={6} md={6} lg={3}>

              <FormControl fullWidth>
                <InputLabel >
                  Work Experience</InputLabel>
                <Select

                    id="demo-simple-select"
                    value={selectedExperience}
                    label="Work Experience"
                    onChange={handleExperienceChange}
                >
                  <MenuItem value="0-2">0-2 years</MenuItem>
                  <MenuItem value="2-5">2-5 years</MenuItem>
                  <MenuItem value="5-8">5-8 years</MenuItem>
                  <MenuItem value="8-10">8-10 years</MenuItem>
                  <MenuItem value="10-12">10-12 years</MenuItem>
                  <MenuItem value="12-15">12-15 years</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid  item xs={4.5} sm={5} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Total Score</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedScore}
                    label="Total Score"
                    onChange={handleScoreChange}
                >
                  <MenuItem value="high-to-low">High to Low</MenuItem>
                  <MenuItem value="low-to-high">Low to High</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item  xs={4.5} sm={5} md={6.5} lg={5.1}>
              <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', padding: '5px'}}
              >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search name' }}
                    type="text"
                    value={searchText}
                    onChange={handleSearchChange}
                />
                <IconButton type="button" sx={{ p: '10px', fontWeight: 'bold' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>        

            <Grid  item xs={0.5} sm={1} md={1} lg={0.8}>
              <Button
                  variant="contained"
                  onClick={handleReset}
                  style={{
                    width: "15%",
                    padding: "7px",
                    fontSize: "15px",
                    fontWeight: "bolder",
                    backgroundColor: "white",
                    textTransform: "none",
                  }}

              >
                <IconButton  color="primary" size="medium">
                  <RestartAltIcon/>
                </IconButton>
              </Button>
            </Grid>

          </Grid>
        </Paper>

        <Paper className='filterContainer'>
          <Grid container spacing={2} >
            {currentData.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card variant='outlined' style={{border: '1px solid gray' , height: '240px', overflow: 'hidden'}}>
                    <CardHeader
                        style={{

                        }}
                        variant="bold"
                        avatar={
                          <InitialsAvatar
                              className="InitialsAvatarinner"
                              style={{
                                backgroundColor:
                                    item.engaged
                                        ? "blue"
                                        : item.result === "SELECTED"
                                            ? "green"
                                            : item.result === "REJECTED"
                                                ? "red"
                                                : "orange",
                              }}
                              name={
                                item.candidateName
                              }
                          />
                        }

                        title={
                          <Box className="Filtersubheadermain">
                            <Typography variant="h6" fontWeight="bold" >
                              {item.candidateName}
                            </Typography>
                          </Box>
                        }

                        subheader={
                          <Box  className="Filtersubheader">
                            <Typography>{item.experience + " years"}</Typography>

                            <Box
                                className="Filtersubheader"
                                style={{
                                  color:
                                      item.engaged
                                          ? "blue"
                                          : item.result === "SELECTED"
                                              ? "green"
                                              : item.result === "REJECTED"
                                                  ? "red"
                                                  : "orange",
                                }}
                            >
                              <Typography className='filterResult'>
                                {item.engaged ? `ENGAGED - ${item.totalScore}` : `${item.result} - ${item.totalScore}`}
                              </Typography>
                            </Box>

                          </Box>
                        }
                    />
                    <CardContent
                        className='FilterCardContent'
                        style={{
                          padding : "0px 10px",
                          height: '90px',
                          overflow: 'hidden',
                          marginTop: '-5px'}}>
                      <Typography
                          style={{
                            padding : "2px 0px",
                            marginTop: '0px'}}
                          variant="body2" color="text.secondary" >
                        Interview Date: {item.interviewDate}
                      </Typography>
                      <div>
                        <Typography variant="subtitle1" component="div" fontWeight={"300"} >
                          {item.techName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" fontWeight={"300"}>
                          Skills: {item.skillNames}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions
                        className="Filterbutt">
                      <Button
                          variant='contained'
                          size="small"
                          onClick={() => {
                            navigate(`/resumemakerui/feedback/${item.formId}`)
                            localStorage.setItem("redirect","/resumemakerui/filter")
                          }
                          }
                          color="primary"
                      > View Details
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </Paper>
        <div style={paginationStyle}>
          <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
          />
        </div>

      </Paper>
  );
};

export default Filter;
