import React from 'react'

function ResumePreview() {
  return (
    <>
   <div>
    <h1>ResumePreview</h1>
    
   </div>
    </>
  )
}

export default ResumePreview