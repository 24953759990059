import React, { useEffect, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  GroupAddRounded,
  Settings as SettingsIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Feedback as FeedbackIcon,
  LaptopMac as LaptopMacIcon,
  Hive as HiveIcon,
  Assignment as AssignmentIcon,
  Widgets as WidgetsIcon,
  ScreenSearchDesktop as ScreenSearchDesktopIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/sidebar.css";

const SideBarlist = () => {
  const [openList, setOpenList] = useState(false);
  const [openMasterList, setOpenMasterList] = useState(false);
  const [openProjectList, setOpenProjectList] = useState(false);
  // const [isWithinTimeRange, setIsWithinTimeRange] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleArrowClick = () => setOpenList(!openList);
  const handleMasterArrowClick = () => setOpenMasterList(!openMasterList);
  const handleProjectArrowClick = () => setOpenProjectList(!openProjectList);

  const role = localStorage.getItem("role");
  const istechLead = localStorage.getItem("techLead");
  const isActive = (path) =>
    location.pathname.startsWith(path) ? "active" : "";

  // useEffect(() => {
  //   const checkTimeRange = () => {
  //     const now = new Date();
  //     const currentHour = now.getUTCHours();
  //     const currentMinute = now.getUTCMinutes();
  //     const startHour = 12; // 6:00 PM IST in UTC
  //     const startMinute = 30; // 6:00 PM IST in UTC
  //     const endHour = 15; // 9:00 PM IST in UTC
  //     const endMinute = 30; // 9:00 PM IST in UTC

  //     if (
  //       (currentHour > startHour ||
  //         (currentHour === startHour && currentMinute >= startMinute)) &&
  //       (currentHour < endHour ||
  //         (currentHour === endHour && currentMinute <= endMinute))
  //     ) {
  //       setIsWithinTimeRange(true);
  //     } else {
  //       setIsWithinTimeRange(false);
  //     }
  //   };

  //   checkTimeRange();
  //   const interval = setInterval(checkTimeRange, 60000); // Check every minute
  //   return () => clearInterval(interval);
  // }, []);

  const renderListItem = (path, icon, text) => (
    <ListItemButton onClick={() => navigate(path)} className={isActive(path)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );

  return (
    <React.Fragment>
      {role !== "PARTNER" && role === "INTERVIEWER" ? (
        <List>
          {renderListItem(
            "/resumemakerui/feedback",
            <FeedbackIcon />,
            "Feedback"
          )}
          {renderListItem(
            "/resumemakerui/dashboard",
            <DashboardIcon />,
            "Resume"
          )}
        </List>
      ) : (
        <List
          className={`inside-content ${isActive(
            "/resumemakerui/interview-details"
          )}`}
        >
          <ListItemButton
            onClick={() => navigate("/resumemakerui/interview-details")}
            className={isActive("/resumemakerui/interview-details")}
          >
            <ListItemIcon>
              <LaptopMacIcon />
            </ListItemIcon>
            <ListItemText primary="Interview" />
            <KeyboardArrowDownIcon
              className={openList ? "upButton active" : "downButton"}
              onClick={handleArrowClick}
            />
          </ListItemButton>
          {openList && (
            <List>
              {renderListItem(
                "/resumemakerui/feedback",
                <FeedbackIcon />,
                "Feedback"
              )}
            </List>
          )}
        </List>
      )}

      {role !== "PARTNER" && role !== "INTERVIEWER" && role !== "INTERNAL" && (
        <List className={`inside-content ${isActive("/resumemakerui/master")}`}>
          <ListItemButton
            onClick={() => navigate("/resumemakerui/master")}
            className={isActive("/resumemakerui/master")}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Master" />
            <KeyboardArrowDownIcon
              className={openMasterList ? "upButton active" : "downButton"}
              onClick={handleMasterArrowClick}
            />
          </ListItemButton>
          {openMasterList && (
            <List>
              {renderListItem(
                "/resumemakerui/users",
                <GroupAddRounded />,
                "Users"
              )}
              {renderListItem(
                "/resumemakerui/interviewpanel",
                <GroupAddRounded />,
                "Interview Panel"
              )}
            </List>
          )}
        </List>
      )}

      {istechLead && (
        <List
          className={`inside-content ${isActive("/resumemakerui/project")}`}
        >
          <ListItemButton
            onClick={() => navigate("/resumemakerui/project")}
            className={isActive("/resumemakerui/project")}
          >
            <ListItemIcon>
              <WidgetsIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
            <KeyboardArrowDownIcon
              className={openProjectList ? "upButton active" : "downButton"}
              onClick={handleProjectArrowClick}
            />
          </ListItemButton>
          {openProjectList && (
            <List>
              {renderListItem(
                "/resumemakerui/project-update-dashboard",
                <AssignmentIcon />,
                "Project Updates"
              )}
              {renderListItem(
                "/resumemakerui/project-details-dashboard",
                <HiveIcon />,
                "Project Details"
              )}
            </List>
          )}
        </List>
      )}
      {role !== "INTERVIEWER" && (
        <List
          className={`inside-content resume ${isActive(
            "/resumemakerui/dashboard"
          )}`}
        >
          {renderListItem(
            "/resumemakerui/dashboard",
            <DashboardIcon />,
            "Resume"
          )}
        </List>
      )}

      {role !== "PARTNER" && role !== "INTERVIEWER" && (
        <List
          className={`inside-content filter ${isActive(
            "/resumemakerui/filter"
          )}`}
        >
          {renderListItem(
            "/resumemakerui/filter",
            <ScreenSearchDesktopIcon />,
            "Search Resource"
          )}
        </List>
      )}
    </React.Fragment>
  );
};

export default SideBarlist;
