// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../src/assets/images/login-illustration.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.login_paperContainer__PPdld {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    width: inherit;\n    background-repeat: no-repeat;\n    height: 100vh;\n    background-position: center;\n    /* border:5px solid red; */\n}\n\n.login_box__sOn6Q {\n    /* border:5px solid blue; */\n    margin-top: 100px;\n    margin-left: 100px;\n}\n\n.login_griditem__BdkXd {\n    display: flex;\n    justify-content: flex-end;\n}\n\n\n@media only screen and (max-width: 600px) {\n    .login_paperContainer__PPdld {\n        height: 50vh;\n    }\n\n    .login_box__sOn6Q {\n        margin-top: 50px;\n        margin-left: 20px;\n        margin-right: 20px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/login.module.css"],"names":[],"mappings":";;AAEA;IACI,yDAAqE;IACrE,sBAAsB;IACtB,cAAc;IACd,4BAA4B;IAC5B,aAAa;IACb,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;;AAGA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":["\n\n.paperContainer {\n    background-image: url(../../src/assets/images/login-illustration.svg);\n    background-size: cover;\n    width: inherit;\n    background-repeat: no-repeat;\n    height: 100vh;\n    background-position: center;\n    /* border:5px solid red; */\n}\n\n.box {\n    /* border:5px solid blue; */\n    margin-top: 100px;\n    margin-left: 100px;\n}\n\n.griditem {\n    display: flex;\n    justify-content: flex-end;\n}\n\n\n@media only screen and (max-width: 600px) {\n    .paperContainer {\n        height: 50vh;\n    }\n\n    .box {\n        margin-top: 50px;\n        margin-left: 20px;\n        margin-right: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paperContainer": "login_paperContainer__PPdld",
	"box": "login_box__sOn6Q",
	"griditem": "login_griditem__BdkXd"
};
export default ___CSS_LOADER_EXPORT___;
