import { columnDefsProject } from "../../utils/AgGridTableColumns";
import { DeleteRounded, EditRounded, Search } from "@mui/icons-material";
import { Button, IconButton, InputBase, Grid, Paper } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AgGridTable from "../AgGridTable/AgGridTable";
import { useDispatch } from "react-redux";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../reduxToolkit/Notification/notificationSlice";
import {
  deleteProjectDetails,
  getProjectDetails,
  getProjectDetailsForAdmin,
} from "../../services/resumemaker-services";

const ROLES = {
  ADMIN: "ADMIN",
  INTERNAL: "INTERNAL",
};

const ProjectDetailsDashBoard = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProjectData = useCallback(async () => {
    try {
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const res =
        role === ROLES.ADMIN || role === ROLES.INTERNAL
          ? await getProjectDetailsForAdmin({ headers })
          : await getProjectDetails(email, { headers });

      setFeedbackData(res.data || []);
    } catch (error) {
      dispatch(setMultiNotificationVariant("error"));
      dispatch(
        setMultiNotificationData([
          { propertyValue: "Failed to fetch project details." },
        ])
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProjectData();
  }, [fetchProjectData]);

  const handleDeleteProject = async (projectId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const res = await deleteProjectDetails(projectId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        const success = res.status === 200 || res.status === 204;
        const message = success
          ? "Project Deleted Successfully."
          : "Something went wrong.";

        dispatch(setMultiNotificationVariant(success ? "success" : "error"));
        dispatch(setMultiNotificationData([{ propertyValue: message }]));

        if (success) fetchProjectData();
      } catch (error) {
        dispatch(setMultiNotificationVariant("error"));
        dispatch(
          setMultiNotificationData([
            { propertyValue: "Failed to delete project." },
          ])
        );
      }
    }
  };

  const handleAddProject = () =>
    navigate("/resumemakerui/project-details/false/0");

  const handleUpdateProject = (id) =>
    navigate(`/resumemakerui/project-details/true/${id}`);

  const buttonRendererDelete = (props) => (
    <IconButton
      size="small"
      onClick={() => handleDeleteProject(props.data.projectId)}
      color="error"
    >
      <DeleteRounded />
    </IconButton>
  );

  const buttonRendererUpdate = (props) => (
    <IconButton
      size="small"
      onClick={() => handleUpdateProject(props.data.projectId)}
      color="success"
    >
      <EditRounded />
    </IconButton>
  );

  const CustomNoRowsOverlay = () => (
    <div className="ag-overlay-no-rows-wrapper">
      <div
        style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}
      >
        NO DATA FOUND FOR LOGGED IN USER
      </div>
    </div>
  );

  const gridOptionsFeedback = {
    headerHeight: 36,
    columnDefs: columnDefsProject,
    frameworkComponents: {
      buttonRendererDeleteResume: buttonRendererDelete,
      buttonRendererUpdateResume: buttonRendererUpdate,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
  };

  return (
    <Paper
      sx={{
        padding: 2,
        marginTop: 2,
        height: "635px",
        backgroundColor: "#F0F0F0",
      }}
    >
      <Grid container justifyContent="space-between" marginY={2}>
        <Paper
          component="form"
          sx={{ display: "flex", alignItems: "center", width: 300 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Project"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <Search />
          </IconButton>
        </Paper>
        <Button
          variant="contained"
          sx={{ backgroundColor: "rgb(33, 80, 162)" }}
          onClick={handleAddProject}
        >
          Add Project
        </Button>
      </Grid>
      <AgGridTable
        searchData={searchValue}
        gridOptions={gridOptionsFeedback}
        data={feedbackData}
        type="projectDetails"
      />
    </Paper>
  );
};

export default ProjectDetailsDashBoard;
