import React, {useEffect, useState} from "react";
import {getUserWithTechNames} from "../../services/resumemaker-services";
import SearchIcon from "@mui/icons-material/Search";
import AgGridTable from "../AgGridTable/AgGridTable";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {columnDefsInterviewer} from "../../utils/AgGridTableColumns";
import {Grid, IconButton, InputBase, Paper, Typography} from "@mui/material";



function InterviewerPanel() {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getUserWithTechNames({
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`, "Content-Type": "application/json",
                    },
                });
                setUserData(res);
                console.log("RES--->",res)
            } catch (error) {
                console.error("Error fetching tech data:", error);
            }
        }

        fetchData();
    }, []);


    const gridOptionsResume = {
        headerHeight: 36,
        columnDefs: columnDefsInterviewer,
        frameworkComponents: {
            customNoRowsOverlay: CustomNoRowsOverlay,
        },
    };

    function CustomNoRowsOverlay() {
        return (
            <div
                className="ag-overlay-no-rows-wrapper"
                style={{
                    backgroundColor: "white",
                    padding: "20px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    fontWeight: "bolder",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    NO DATA FOUND FOR LOGGED IN USER
                </div>
            </div>
        );
    }


    return (

        <>
            <Paper style={{padding: '15px', marginTop: '30px' , height: '635px', backgroundColor: '#F0F0F0'}}>

                <div>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                        }}
                    >
                        <Typography style={{fontSize: "35px", fontWeight: "bold"}}>
                            Interviewer Panel
                        </Typography>
                        <Paper
                            component="form"
                            sx={{ display: "flex", alignItems: "center", width: 300 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search name"
                                inputProps={{ "aria-label": "search name" }}
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e?.target?.value || "");
                                }}
                            />
                            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                    </Grid>
                </div>
                <AgGridTable
                    searchData={searchValue}
                    gridOptions={gridOptionsResume}
                    data={userData}
                    type="panel"
                />
            </Paper>
        </>
);
}

export default InterviewerPanel;
