import { format, parseISO } from "date-fns";
export const resumedatamapper = (data) => {
  let temparray = data.map((ele) => {
    let obj = {};
    obj["name"] = ele.empName;
    obj["resumeUUID"] = ele.resumeId;
    obj["designation"] = ele.designation;
    obj["skills"] = ele.languages;
    obj["createdBy"] = ele.fullName;
    return obj;
  });
  return temparray;
};

export const usersdatamapper = (userData) => {
  let temparray = userData.map((ele) => {
    let obj = {};
    obj["name"] = ele.fullName;
    obj["userUUID"] = ele.userId;
    obj["email"] = ele.email;
    obj["role"] = ele.roleName;
    obj["status"] = ele.activeStatus === "Y" ? "Active" : "New";
    return obj;
  });
  return temparray;
};

export const interviewerdatamapper = (userData) => {
  let temparray = userData.map((ele) => {
    let obj = {};
    obj["name"] = ele.fullName;
    obj["technologies"] = ele.technologies;
    return obj;
  });
  return temparray;
};

export const feedbackdatamapper = (userData) => {
  let temparray = userData.map((ele) => {
    let obj = {};
    obj["candidateId"] = ele.candidateId;
    obj["candidate"] = ele.candidateName;
    obj["interviewType"] =
      ele.interviewType === "Humancloud_Internal" ? "Internal" : "Converge";
    obj["interviewRound"] =
      ele.interviewRound === "L1"
        ? "Level 1"
        : ele.interviewRound === "L2"
        ? "Level 2"
        : "Level 3";
    obj["interviewer"] = ele.interviewerName;
    obj["status"] = ele.engaged ? "ENGAGED" : ele.result;
    obj["submittedDate"] = ele.createdDate; //format(parseISO(ele.createdDate),'dd/MM/yyyy');
    obj["formId"] = ele.formId;
    return obj;
  });
  return temparray;
};

export const projectDataMapper = (data) => {
  if (!Array.isArray(data)) {
    console.error("Expected an array but received:", data);
    return [];
  }

  let tempArray = data.map((ele) => {
    let obj = {};
    obj["projectId"] = ele.id;
    obj["projectName"] = ele.projectName || "N/A";
    obj["description"] = ele.description || "N/A";
    obj["techLeadName"] = ele.techLeadName || "N/A";
    obj["techLeadEmail"] = ele.techLeadEmail || "N/A";
    return obj;
  });

  return tempArray;
};

export const projectUpdatesDataMapper = (data) => {
  if (!Array.isArray(data)) {
    console.error("Expected an array but received:", data);
    return [];
  }

  let tempArray = data.map((ele) => {
    let obj = {};
    obj["updateId"] = ele.id;
    obj["projectName"] = ele.projectDetails?.projectName || "";
    obj["techLeadName"] = ele.projectDetails?.techLeadName || "";
    obj["generalUpdate"] = ele.generalUpdate || [];
    obj["highLights"] = ele.highLights || [];
    obj["status"] = ele.status || "";
    obj["createdDate"] = format(parseISO(ele.createdDate), "dd/MM/yyyy") || [];
    return obj;
  });

  return tempArray;
};

export const feedbackKpidatamapper = (inData) => {
  let temparray = [];

  inData.forEach((ele) => {
    let existingObj = temparray.find(
      (obj) => obj.interviewer === ele.interviewerName
    );

    if (existingObj) {
      // Object for the interviewer already exists, update the values
      if (ele.interviewType === "Humancloud_Internal") {
        existingObj.internalSe = ele.Selected;
        existingObj.internalRe = ele.Rejected;
        existingObj.internalHo = ele.Hold;
        existingObj.internalTo = ele.Total;
      } else if (ele.interviewType === "The_Converge") {
        existingObj.convergeSe = ele.Selected;
        existingObj.convergeRe = ele.Rejected;
        existingObj.convergeHo = ele.Hold;
        existingObj.convergeTo = ele.Total;
      }
    } else {
      // Object for the interviewer doesn't exist, create a new object
      let newObj = {
        interviewer: ele.interviewerName,
        internalSe:
          ele.interviewType === "Humancloud_Internal" ? ele.Selected : 0,
        convergeSe: ele.interviewType === "The_Converge" ? ele.Selected : 0,
        internalRe:
          ele.interviewType === "Humancloud_Internal" ? ele.Rejected : 0,
        convergeRe: ele.interviewType === "The_Converge" ? ele.Rejected : 0,
        internalHo: ele.interviewType === "Humancloud_Internal" ? ele.Hold : 0,
        convergeHo: ele.interviewType === "The_Converge" ? ele.Hold : 0,
        internalTo: ele.interviewType === "Humancloud_Internal" ? ele.Total : 0,
        convergeTo: ele.interviewType === "The_Converge" ? ele.Total : 0,
      };

      temparray.push(newObj);
    }
  });
  return temparray;
};

export const putFeedBackDataMapper = (
  data,
  softSkillApi,
  currentSoftSkillList,
  techNameId,
  currentTechnologyList,
  techId,
  selectedMultipleLang,
  feedbackFormData
) => {
  let tempData = JSON.parse(JSON.stringify(data));
  tempData.softSkillRatings = softSkillApi.map((ele) => {
    let objRating = { ...ele };
    objRating["rating"] = currentSoftSkillList[ele.skillName]
      ? parseInt(currentSoftSkillList[ele.skillName])
      : 0;

    return objRating;
  });
  let techListWithTechName = {};
  Object.values(currentTechnologyList).forEach((techno) => {
    let tempbTechObj = {};
    tempbTechObj = {
      rating: techno.rating ? parseInt(techno.rating) : 0,
      skillName: techno.skillName,
    };
    if (techId[techno.skillName]) {
      tempbTechObj["skillId"] = techId[techno.skillName];
    }
    if (techListWithTechName[techno.techName]) {
      techListWithTechName[techno.techName] = [
        ...techListWithTechName[techno.techName],
        tempbTechObj,
      ];
    } else {
      techListWithTechName[techno.techName] = [tempbTechObj];
    }
  });

  let tempTechonologyFull = selectedMultipleLang.map((lang) => {
    let tempTechFullObj = {
      techName: lang,
      techSkills: techListWithTechName[lang],
    };
    if (techNameId[lang]) {
      tempTechFullObj["techId"] = techNameId[lang];
    }
    return tempTechFullObj;
  });
  tempData.technologyRating = tempTechonologyFull;
  tempData["createdBy"] = feedbackFormData.createdBy;
  tempData["interviewerName"] = feedbackFormData.interviewerName;
  tempData["createdDate"] = feedbackFormData.createdDate;
  delete tempData["improvementAreas"];
  return tempData;
};
