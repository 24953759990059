import React from 'react'

function Master() {
  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
          <div style={{ height: '200px' }}>
              <img src="https://i.imgur.com/fYLPWQ1.png" height="90%" width="90%" alt="Centered Image" />
          </div>
      </div>

  )
}

export default Master